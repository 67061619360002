.m-numberinput{
  display: flex;
  margin-right:15px;
  margin-bottom:5px;
  align-items: center;

  input{
    margin-left:5px;
    width:60px;
    text-align:right;
    padding: 5px 10px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    display: inline-block;
    position: relative;
    color:#FFFFFF;
    border:none;
    -webkit-appearance: none;

    &:focus{
      outline:none;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;}

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  &:last-child{
    margin-right:0;
  }
}