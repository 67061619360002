@import "normalize-css";
@import "references/mixins";
@import "references/typography";
@import "references/variables";
@import "minigrid";
@import url("https://use.typekit.net/vto8ztj.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes copied {
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  50% {
    opacity: 1;
    transform: translateX(-20%);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%);

  }
}

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  @include font-size(1.6, 2.4);
  background: linear-gradient(110deg, #222, #010101);
  background-size: 100% 100%;
  animation: gradient 8s ease infinite;
  height: 100vh;
  color: $c-white;
  font-family: $f-body;
  padding: 0;
  position:relative;

  &:after{
    content:'';
    display:block;
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background:url('../components/images/bg-texture.png') repeat;
    z-index:-1;
  }

}

footer {
  padding: 60px 0 30px;
  text-align: center;

  ul {
    list-style: none;
    margin: 0 0 10px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      margin: 0 8px;

      a {
        display: block;

        &:hover {
          svg {
            fill: rgba(255, 255, 255, 0.6);
          }
        }

        svg {
          height: 20px;
          fill: $c-white;
          transition: all .3s ease-in-out;
        }
      }
    }
  }
}


h1, h2, h3, h4 {
  font-family: $f-title;
  margin: 0 0 10px;
}

h1, h2, h3, p {
  color: $c-white;

  &.preamble {
    @include font-size(2.6, 3.2);
  }
}

h1 {
  font-size: clamp(40px, calc(2.5rem + ((1vw - 0px) * 2.0833)), 80px);
  line-height: clamp(40px, calc(2.5rem + ((1vw - 0px) * 2.0833)), 80px);
  min-height: 0;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  font-weight: 700;
  margin: 20px 0 8px;
  display: block;

  &:first-child {
    margin-top: 0;
  }

  & + .form-row {
    margin-top: 5px !important;
  }
}

img{
  max-width:100%;
}

.button{
  padding:10px 30px;
  border-radius:5px;
  text-decoration: none;
  background:#00A1BE;
  color:$c-white;
  font-weight:600;
  text-transform: uppercase;
  margin-top:5px;
  display:inline-flex;
  align-items: center;
  box-shadow:0 6px 10px rgba(0, 199, 235, 0.3);
  transition:all .3s ease-in-out;

  svg{
    width:15px;
    height:15px;
    margin-right:5px;
    fill:$c-white;
    display:block;
  }

  &:hover{
    background:lighten(#00A1BE, 10%);
    box-shadow:0 5px 30px rgba(0, 199, 235, 0.6);
  }
}

.l-intro {
  width: 100%;
  text-align: center;
  padding: 5vh 0 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;


  .e-image {
    max-width: 360px;
    margin: 0 auto -50px;

    img {
      width: 100%;
    }
  }

  p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.main{
  background:rgba(255,255,255,0.05);
  background: linear-gradient(20deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.1) 100%);
  border:1px solid rgba(255,255,255,0.1);
  border-radius:20px;
  padding:20px;
  position:relative;
  box-shadow:0 5px 20px rgba(0,0,0,0.3);
  backdrop-filter:blur(1px);


  @media(min-width:991px){
    padding:50px;

  }
}

.l-left {
  @include grid(12, 12, 5.5, 5, 5);

  .e-form {
    .e-title, label {
      @include font-size(1.8, 2.6);
    }

    .form-row {
      margin: 20px 0;
      padding: 20px;
      background: rgba(0,0,0,0.2);
      box-shadow:0 5px 10px rgba(0,0,0,0.3);
      border-radius: 5px;

      .timeset{
        display:flex;
        flex-wrap:wrap;
        //justify-content: space-between;
        padding-bottom:10px;
        margin-bottom:10px;
        border-bottom:rgba(255,255,255,0.1) 1px solid;
      }

      & > div {
        margin-top: 5px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .toggle {
      position: relative;
      display: block;
      width: 100%;
      margin-bottom:10px;

      &:first-child {
        margin-top: 0 !important;
      }

      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;

        & + label {
          align-items: center;
          margin: 0;
          position: relative;
          display: inline-flex;
          width: 100%;

          &:hover {
            cursor: pointer;
          }

          &:before, &:after {
            content: '';
            display: block;
          }

          &:before {
            position: absolute;
            right: 28px;
            top: 3px;
            height: 20px;
            width: 20px;
            background: $c-white;
            border-radius: 50%;
            z-index: 20;
            transition: all .3s ease-in-out;

          }

          &:after {
            position: absolute;
            border: 1px solid rgba(0, 0, 0, 0.4);
            border-radius: 40px;
            right: 0;
            width: 50px;
            height: 25px;
            transition: all .3s ease-in-out;
            background: rgba(184, 0, 0, 0.8);
            margin-left: 10px;
          }
        }

        &:checked {
          + label {
            &:before {
              right: 4px;
            }

            &:after {
              background: #00A1BE;
            }
          }
        }
      }
    }
  }
}

.l-right {
  @include grid(12, 12, 6.5, 7, 7, 7);
  align-items: center;
  justify-content: center;
  display: flex;
}

.l-examples {
  @include grid(12, 12, 12, 12, 12);
  display: flex;
  flex-direction: row;

  .box {
    width: 100%;
    display: block;
    margin-bottom: 0px;
    margin-right: 10px;

    & + .box {
      margin-right: 0;
      margin-left: 10px;
    }

    .inner {
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    }

    &.light {
      .inner {
        background: $c-white;

        .chat-line {
          .message {
            color: var(--chatColour, #444);
          }
        }
      }
    }

    &.dark {
      .inner {
        background: #18181B;
        width: 100%;

        .chat-line {
          .message {
            color: var(--chatColour, #444);
          }

        }
      }
    }
  }
}

.copyurl{
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding-top:10px;
  margin-top:10px;
  border-top:rgba(255,255,255,0.1) 1px solid;

  .innerurl{
    margin-left:10px;
    padding:5px;
    background: #222;
    width:100%;
    border-radius:5px;
    max-width:calc(100% - 50px);
    overflow:hidden;
    position:relative;

    &:hover{
      cursor:pointer;
    }

    #timerURL{
      color:#555;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width:calc(100% - 30px);
      display:block;
    }

    button{
      background:none;
      border:none;
      padding:0;
      position:absolute;
      right:10px;
      top:7px;

      svg{
        width:20px;
        height:20px;
        fill:#FFFFFF;
        transition:all .3s ease-in-out;
      }

      .e-copy{
        &.copied {
          svg {
            transform: scale(0.8);
          }

          &:before {
            @include font-size(1.4, 1.6);
            color: $c-white;
            content: 'Copied';
            position: absolute;
            right: calc(100% + 5px);
            transform: translateX(0%);
            opacity: 0;
            animation: copied 2s ease-in-out forwards;
            z-index: 0;
            padding:2px 5px;
            border-radius:5px;
            background:#111;
          }
        }
      }
    }
  }
}


//Support Block
.m-support{
  padding:100px 0 0;

  .panel{
    @include grid(12,4, 3, 3, 3);
    margin:20px 0;

    .e-panelwrapper{
      padding:10px 20px;
      border-radius:10px;
      background:rgba(0,0,0,0.5);
    }

    .aitum-snapshot{
      border-radius:10px;
      box-shadow:0 3px 6px rgba(0,0,0,0.4);
      border:1px solid rgba(255,255,255, 0.02);
    }
  }

  .text{
    @include grid(12, 5, 3, 3, 3);
    margin:20px 0;

    h2{
      @include font-size(5, 6);
      margin-bottom:0;
    }
    p{
      margin-top:5px;
    }
  }
}

iframe{
  width:100%;
  border:none;
  height:100%;
  min-height:300px;
}